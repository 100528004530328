import React from "react";
import "./footer.css";

function Footer() {
  return (
    <footer className="footer" id="footer">
      <div className="footer__container container">
        <h1 className="footer__title">Ilker</h1>

        <ul className="footer__list">
          <li>
            <a href="#about" className="footer__link">
              About
            </a>
          </li>
          <li>
            <a href="#career" className="footer__link">
              Career
            </a>
          </li>
          <li>
            <a href="#portfolio" className="footer__link">
              Projects
            </a>
          </li>
          
        </ul>
        <div className="footer__social">
          <a
            href="https://www.linkedin.com/in/ilker-atar-828a21160/"
            className="footer__social-link"
            target="_blank"
          >
            <i className="bx bxl-linkedin"></i>
          </a>

          <a
            href="https://github.com/ilkeratar"
            className="footer__social-link"
            target="_blank"
          >
            <i className="bx bxl-github"></i>
          </a>

          <a
            href="https://discord.com/users/458001955151937566"
            className="footer__social-link"
            target="_blank"
          >
            <i className="bx bxl-discord"></i>
          </a>
        </div>

        <span className="footer__copy">&#169; All rigths reserved.</span>
      </div>
    </footer>
  );
}

export default Footer;
