import React, { useState } from 'react';
import "./career.css";
function Career() {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab=(index)=>{
        setToggleState(index);
    };

  return (
    <section className="career section" id="career">
        <h2 className="section__title">Career</h2>
      <span className="section__subtitle">My journey</span>

      <div className="career__container container">
        <div className="career__tabs">
            <div className={toggleState === 1 
            ? "career__button career__active button--flex" 
            : "career__button button--flex"
                }
                onClick={()=>toggleTab(1)}
            >
                <i className="uil uil-graduation-cap career__icon"></i> Education
            </div>

            <div className={toggleState === 2 
            ? "career__button career__active button--flex" 
            : "career__button button--flex"
                }
                onClick={()=>toggleTab(2)}
                >
                <i className="uil uil-briefcase-alt career__icon"></i> Experience
            </div>
        </div>

        <div className="career__sections">
            <div className={toggleState===1?
            "career__content career__content-active" :
            "career__content "}>
                <div className="career__data">
                    <div>
                        <h3 className="career__title">Computer Engineering</h3>
                        <span className="career__subtitle">
                            Selcuk University
                            </span>
                        <div className="career__calender">
                            <i className="uil uil-calendar-alt"></i>2019 - 2023
                        </div>
                    </div>
                    <div>
                        <span className="career__rounder"></span>
                        <span className="career__line"></span>
                    </div>
                </div>
                <div className="career__data">
                    <div></div>
                    <div>
                        <span className="career__rounder"></span>
                        <span className="career__line"></span>
                    </div>
                    <div>
                        <h3 className="career__title">Web Programming</h3>
                        <span className="career__subtitle">Gebze STFA Vocational and Technical Anatolian High School</span>
                        <div className="career__calender">
                            <i className="uil uil-calendar-alt"></i>2014 - 2018
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={toggleState===2?
            "career__content career__content-active experience" :
            "career__content "}>
                <div className="career__data experience">
                    <div>
                        <h3 className="career__title">Software Developer</h3>
                        <span className="career__subtitle">
                            Curator Studios
                            </span>
                            <p className="career__description">
                            - Worked as a Software developer, I contributed to the development of a multiplayer auto battler game.
                            
                            </p>
                            <p className="career__description">
                            - Managed the design and development process of the client-side screens of the game.
                            </p>
                            
                        <div className="career__calender">
                            <i className="uil uil-calendar-alt"></i> Dec. 2022 - Apr. 2023
                        </div>
                    </div>
                </div>
                <div className="career__data experience">
                    <div>
                        <h3 className="career__title">Game Developer</h3>
                        <span className="career__subtitle">Hifive Games</span>
                        <span className="career__description">
                        - Worked as a game developer, following the Solid principles and using Design patterns.
                        </span>
                        <div className="career__calender">
                            <i className="uil uil-calendar-alt"></i> Apr. 2022 - Aug. 2022
                        </div>
                    </div>
                </div>
                <div className="career__data experience">
                    <div>
                        <h3 className="career__title">Software Intern</h3>
                        <span className="career__subtitle">Pulver Chemistry</span>
                        <span className="career__description">
                        - Worked as a game developer, following the Solid principles and using Design patterns.
                        </span>
                        <div className="career__calender">
                            <i className="uil uil-calendar-alt"></i> Sep. 2017 - Jun. 2018
                        </div>
                    </div>
                </div>
                <div className="career__data experience">
                    <div>
                        <h3 className="career__title">System And Network Expertise Intern</h3>
                        <span className="career__subtitle">Devinix Solutions AB (Stockholm,Sweden)</span>
                        <p className="career__description">- Erasmus + Project.</p>
                        <div className="career__calender">
                            <i className="uil uil-calendar-alt"></i> 2018 March
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </section>
  )
}

export default Career
