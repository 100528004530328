import React from "react";

function Social() {
  return (
    <div className="home__social">
      <a href="https://www.linkedin.com/in/ilker-atar-828a21160/" className="home__social-icon" target="_blank">
        <i className="uil uil-linkedin"></i>
      </a>

      <a href="https://github.com/ilkeratar" className="home__social-icon" target="_blank">
      <i className="uil uil-github-alt"></i>
      </a>

      <a href="https://discord.com/users/458001955151937566" className="home__social-icon" target="_blank">
      <i className="uil uil-discord"></i>
      </a>
    </div>
  );
}

export default Social;
