import Work1 from "../../assets/usermanagement2.PNG";
import Work2 from "../../assets/Joblist1.PNG";
import Work3 from "../../assets/project3.jpg";

export const projectsData=[
    {
        id:1,
        image:Work1,
        title:"User Management System",
        description:"The project was developed with the aim of improving my skills and gaining more experience with technologies such as React, JWT and Zustand.",
        techs:"ReactJS, Zustand, JWT",
        link:"https://github.com/ilkeratar/user-management-system"
    },
    {
        id:2,
        image:Work2,
        title:"CRUD Job List",
        description:"CRUD app was developed with the aim of improving my skills. Used React Hooks, Json-server, Context API, Axios, Formik, Yup and more.",
        techs:"ReactJS, Context API",
        link:"https://github.com/ilkeratar/crud-app-jsonserver"
    }
]