import React from 'react'

const WorkItems= ({item})=> {
  return (
    <div className="work__card" key={item.id}>
        <img src={item.image} alt="" className='work__img'/>
        <h3 className="work__title">{item.title}</h3>
        <p className='work__description'>{item.description}</p>
        <p className='work__tech-used'>Tech Used: {item.techs}</p>
        
        <a target='_blank' href={item.link}  rel="noopener noreferrer" className="button button-flex work__button">
            Source Code <i className="bx bx-right-arrow-alt work__button-icon"></i>
        </a>
        
    </div>
  )
}

export default WorkItems