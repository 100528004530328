import './App.css';
import About from './components/about/About';
import Career from './components/career/Career';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Home from './components/home/Home';
import Skills from './components/skills/Skills';
import Work from './components/work/Work';
function App() {
  return (
    <div className="App">
      <Header />

      <main className='main'>
        <Home />
        <About />
        <Skills />
        <Career />
        <Work />
        <Footer />
      </main>
    </div>
  );
}

export default App;
